import alert from "sweetalert2";
export const toast = {
  show(detail, is_toast = true, position = "top-right") {
    alert.fire({
      toast: is_toast,
      position: position,
      showConfirmButton: false,
      // showCancelButton: true,
      showCloseButton: true,
      timer: 10000,
      icon: detail.icon,
      title: detail.title,
      html: detail.message,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", alert.stopTimer);
        toast.addEventListener("mouseleave", alert.resumeTimer);
      },
    });
  },
};
